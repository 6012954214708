<template>
  <header class="header bg-white">
    <div class="container header-wrapper">
      <div class="header-left">
        <router-link
          :to="router?.name != 'parent-main' ? '/main' : '/parent-main'"
        >
          <img
            class="header-left__logo"
            src="@/assets/mars-logo.png"
            alt="mars-logo"
          />
        </router-link>
      </div>
      <div class="header-center" v-if="router?.name != 'parent-main'">
        <router-link class="header-center__link" to="/main">
          <i class="fa-solid fa-house"></i>
          {{ $t("learning") }}
        </router-link>
        <router-link class="header-center__link" to="/blog">
          <i class="fa-solid fa-blog"></i>
          {{ $t("blog-title") }}
        </router-link>

        <router-link class="header-center__link" to="/space-shop">
          <i class="fa-solid fa-store"></i>

          {{ $t("mars-shop") }}
        </router-link>
      </div>
      <div class="header-right">
        <button
          @click.prevent.stop="openPostModal"
          class="create-post"
          :disabled="!studentInfo?.is_client"
        >
          + {{ $t("header.header-create-post.post-btn") }}
        </button>
        <AnsAndQues
          v-if="
            studentInfo?.id &&
            !studentInfo?.surveys &&
            router?.name != 'parent-main' &&
            studentInfo?.is_client
          "
        />
        <!-- <SetName
          v-if="
            studentInfo?.id &&
            router?.name != 'parent-main' &&
            (!String(studentInfo?.first_name).trim().length ||
              !String(studentInfo?.last_name).trim().length)
          "
          :student="studentInfo"
        /> -->
        <!-- <NotificationsCom /> -->
        <BirthCertificate
          v-if="studentInfo?.id && !studentInfo?.metric && studentInfo?.surveys"
        />
        <div v-popover="popoverRef">
          <profile-image
            :borderImgUrl="studentInfo?.rank?.image"
            :canChange="false"
            :modmeId="studentInfo?.external_id"
            :imageUrl="studentInfo?.avatar"
            className="header-right__avatar"
            :is_open_dialog="false"
          />
        </div>
      </div>
    </div>

    <el-popover
      ref="popoverRef"
      trigger="click"
      popper-class="header-right__popover-wrapper"
      popper-style="width: unset"
    >
      <header-feature
        :student_name="studentInfo?.first_name + ' ' + studentInfo?.last_name"
        :imageUrl="studentInfo?.avatar"
      />
    </el-popover>
    <el-dialog v-model="postModal" class="create-post-modal">
      <create-post @close="created" />
    </el-dialog>
  </header>
</template>

<script setup>
import { onMounted, ref, watch, onBeforeMount, defineEmits } from "vue";
import { ProfileImage } from "@/entities";
import { HeaderFeature } from "@/features";
import "./style/header.css";
import CreatePost from "@/features/CreatePost/CreatePost.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import AnsAndQues from "@/components/AnsAndQues.vue";
// import SetName from "@/components/SetName.vue";
import { useGtag } from "vue-gtag-next";
// import NotificationsCom from "@/components/NotificationsCom.vue";
import BirthCertificate from "@/components/BirthCertificate.vue";
const emits = defineEmits(["post-created"]);

const router = useRoute();

const studentInfo = ref(null);
const store = useStore();

onMounted(() => {
  studentInfo.value = store.state.student.studentInfo;
});

watch(
  () => store.state.student.studentInfo,
  (newValue) => {
    studentInfo.value = newValue;
  }
);

const popoverRef = ref();

const postModal = ref(false);

const created = () => {
  postModal.value = !postModal.value;
  emits("post-created");
};

onBeforeMount(async () => {
  await store.dispatch("getMe");
});

const gtag = useGtag();
const openPostModal = () => {
  gtag.event("create-post", {
    event_category: "post",
    event_label: "create-post",
    value: 1,
  });
  postModal.value = true;
};
</script>
