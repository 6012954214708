<template>
  <div class="share-link">
    <div class="share-link-left">
      <img
        class="share-link-left-img"
        src="@/assets/share-icon-left.svg"
        alt=""
      />

      <div>
        <p class="share-link-left-titleshr">
          {{ $t("main.share-link.heading") }}
        </p>
        <p class="share-link-left-textshr">
          {{ $t("main.share-link.title") }}
        </p>
      </div>
    </div>
    <div class="share-link-right">
      <div class="certificate-check-right">
        <button class="share-link-left-btn p-4" @click="openDialog">
          Share
          <img src="@/assets/arrow-right-blue.svg" alt="" />
        </button>
      </div>
      <el-dialog v-model="dialogVal" class="share-link-custom-share-dialog">
        <share-link @close="dialogVal = !dialogVal" />
      </el-dialog>
    </div>
  </div>
</template>
<style lang="scss">
@import "./style/share-link.scss";
</style>

<script setup>
import { ref } from "vue";
import ShareLink from "@/features/ShareLink/ShareLink.vue";
const dialogVal = ref(false);
import { useGtag } from "vue-gtag-next";

const gtag = useGtag();
const openDialog = () => {
  dialogVal.value = true;
  gtag.event("share_link", {
    event_category: "share_link",
    event_label: "share_link",
    value: 1,
  });
};
</script>
